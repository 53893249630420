import Rollbar from "rollbar"
import isbot from "isbot"

// Don't use literal strings for ignored messages
// they're converted into regexes anyway by rollbar
// if they're already regexes we don't need to worry about double escaping regex characters.

const TRANSLATED_IGNORED_MESSAGES = {
  // spellr:disable
  ar: [/^مُلغى$/],
  ca: [/^cancel·lat$/],
  cs: [/^zrušeno$/],
  da: [/^annulleret$/],
  de: [/^Abgebrochen$/],
  el: [/^ακυρώθηκε$/],
  en: [/^cancelled$/],
  es: [/^cancelado$/],
  fi: [/^Kumottu$/],
  fr: [/^annulé$/],
  hu: [/^visszavonva$/],
  id: [/^dibatalkan$/],
  it: [/^annullato$/],
  ja: [/^キャンセルしました$/],
  ko: [/^취소됨$/],
  nb: [/^avbrutt$/],
  nl: [/^geannuleerd$/],
  no: [/^avbrutt$/],
  pl: [/^anulowane$/],
  pt: [/^cancelado$/],
  ro: [/^anulat$/],
  ru: [/^отменено$/],
  sk: [/^zrušené$/],
  sv: [/^avbruten$/],
  th: [/^ถูกยกเลิก$/],
  tr: [/^vazgeçildi$/],
  uk: [/^скасовано$/],
  vi: [/^đã h(ủy|uỷ)$/], // the diacritic appears in both positions
  zh: [/^已取消$/],
  // spellr:enable
}

const IGNORED_MESSAGES = [
  /^The user aborted a request\./,
  /^Cannot read properties of undefined \(reading 'firefoxSample'\)/,
  /^Fetch is aborted/,
  /^NetworkError when attempting to fetch resource\./,
  /^Failed to fetch/,
  /^Failed to read the '(?:local|session)Storage' property from 'Window': Access is denied for this document\./,
  /^Cannot read property isTronLink of undefined\/null expression/,
  /^jQuery is not defined/,
  /^Script error\.?$/,
  /^The fetching process for the media resource was aborted by the user agent at the user's request\.$/,
  /^The operation was aborted\./,
  /^The play\(\) request was interrupted by a call to pause\(\)\./,
  /^The play method is not allowed by the user agent\./,
  /^NotSupportedError: The media resource indicated by the src attribute or assigned media provider object was not suitable\./,
  /^Object Not Found Matching Id/,
]

Rollbar.init({
  accessToken: window.rollbarClientToken,
  enabled: window.rollbarEnvironment === "production" || window.rollbarEnvironment === "staging",
  captureUncaught: true,
  captureUnhandledRejections: true,
  ignoredMessages: IGNORED_MESSAGES.concat(TRANSLATED_IGNORED_MESSAGES[window.navigator.language.split("-")[0]] || []),
  payload: {
    environment: window.rollbarEnvironment,
  },
  checkIgnore: () => {
    if (navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1) {
      return true
    }
    const foundFF = window.navigator.userAgent.match(/ Firefox\/(\d+)/)
    if (foundFF) {
      // ignore Firefox version below 52 (released in 2017, adds async/await)
      return +foundFF[1] < 52
    }
    const foundCh = window.navigator.userAgent.match(/ Chrome\/(\d+)/)
    if (foundCh) {
      // ignore Chrome version below 55 (released in 2016, adds async/await)
      return +foundCh[1] < 55
    }
    const foundSaf = window.navigator.userAgent.match(/ Version\/(\d+).* Safari\//)
    if (foundSaf) {
      // ignore Safari version below 12 (released in 2016)
      return +foundSaf[1] < 12
    }

    if (isbot(window.navigator.userAgent)) {
      return true
    }

    const foundSplash = window.navigator.userAgent.match(/ splash Version\/d*/)
    if (foundSplash) {
      // ignore Splash https://user-agents.net/bots/splash

      return true
    }

    return false
  },
})
